<template>
	<div class="mstp-map">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="tile">
				<div class="tile-title">
					<span>能耗统计</span><span><i class="el-icon-more"></i></span>
				</div>
				<div class="tile-top">
					<ul>
						<li>●办公楼</li>
						<li>●机房</li>
						<li>●基站</li>
						<li>●营业厅</li>
					</ul>
				</div>
				<div class="tile-details">
					<div class="left">
						<span>能耗统计</span>
						<span>费用统计</span>
						<span>节能减排</span>
						<span>对标统计</span>
					</div>
					<div class="middle">
						<div class="histogram1" id="histogram1"></div>
						<div class="addTime">
							<span class="label">加入对比时间:</span>
							<input class="form-control" />
						</div>
						<div class="changeHome">
							<div class="label">站房</div>
							<select class="select">
								<option>基站A</option>
								<option>基站B</option>
								<option>基站C</option>
								<option>基站D</option>
							</select>
						</div>
					</div>
					<div class="lable">
						<div class="contnet">
							<label style="height: 30px"></label>
							<h3>单位面积总能耗</h3>
							<span>分时间尺度展示当前建筑单位面积总能耗。同</span>
							<span>时通过下拉时间列表自由比对历史单位面积总</span>
							<span>能耗值。</span><br />
							<span style="font-size: 23px">0.65</span
							><span style="margin-right:100px">kwh/(m²天)</span>
							<span style="font-size: 23px">↑10</span><span>%</span><br />
							<span style="margin-right:60px">本月平均单位面积总能耗</span>
							<span>与同类建筑相比</span>
						</div>
					</div>
				</div>
			</div>
			<div class="tile-title"><span>建筑系统能效指标</span><span></span></div>
			<div class="item">
				<div class="box">
					<div class="box-title">
						<span>人均空调系统能耗</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content">
						<p>
							分时间尺度的展示当前却水泵用电量，同时通过下拉时间列表自由对比历史冷却水泵用电量数据。
						</p>
						<div class="histogram2" id="histogram2"></div>
						<div class="tab">
							<ul>
								<li class="active">日视图</li>
								<li>周视图</li>
								<li>月视图</li>
								<li>年视图</li>
							</ul>
						</div>
						<div class="addTime">
							<input class="form-control" />
							<span class="label">加入对比时间</span>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>照明系统能效比</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content">
						<p>
							分时间尺度的展示当前建筑空调系统用电，同时通过下拉时间列表自由对比历史空调用电数据。
						</p>
						<div class="nullBox"></div>
						<div class="tab">
							<ul>
								<li class="active">日视图</li>
								<li>周视图</li>
								<li>月视图</li>
								<li>年视图</li>
							</ul>
						</div>
						<div class="addTime">
							<input class="form-control" />
							<span class="label">加入对比时间</span>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-title">
						<span>空调系统能效比</span>
						<span><i class="el-icon-more"></i></span>
					</div>
					<div class="box-content">
						<p>
							分时间尺度的展示当前数据中心用电，同时通过下拉时间列表自由对比历史数据中心用电数据。
						</p>
						<div class="histogram2" id="histogram3"></div>
						<div class="tab">
							<ul>
								<li class="active">日视图</li>
								<li>周视图</li>
								<li>月视图</li>
								<li>年视图</li>
							</ul>
						</div>
						<div class="addTime">
							<input class="form-control" />
							<span class="label">加入对比时间</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
import * as echarts from 'echarts'
export default {
	components: {
		sidebar,
		headerTop,
	},
	methods: {
		histogram1() {
			let myChart = echarts.init(document.getElementById('histogram1'))
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#012f4a', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#4c9bfd',
					},
				},
				yAxis: {
					type: 'value',
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#4c9bfd',
					},
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '能耗',
						data: [
							{
								value: 123,
								itemStyle: {
									color: 'rgb(255, 15, 0)',
								},
							},
							{
								value: 122,
								itemStyle: {
									color: 'rgb(255, 102, 0)',
								},
							},
							{
								value: 118,
								itemStyle: {
									color: 'rgb(255, 158, 1)',
								},
							},
							{
								value: 30,
								itemStyle: {
									color: 'rgb(252, 210, 2)',
								},
							},
							{
								value: 350,
								itemStyle: {
									color: 'rgb(248, 255, 1)',
								},
							},
							{
								value: 330,
								itemStyle: {
									color: 'rgb(176, 222, 9)',
								},
							},
							{
								value: 200,
								itemStyle: {
									color: 'rgb(13, 142, 207)',
								},
							},
							{
								value: 120,
								itemStyle: {
									color: 'rgb(13, 82, 209)',
								},
							},
							{
								value: 140,
								itemStyle: {
									color: 'rgb(42, 12, 208)',
								},
							},
							{
								value: 168,
								itemStyle: {
									color: '#a90000',
								},
							},
							{
								value: 133,
								itemStyle: {
									color: 'rgb(138, 12, 207)',
								},
							},
							{
								value: 20,
								itemStyle: {
									color: 'rgb(205, 13, 116)',
								},
							},
						],
						type: 'bar',
					},
					{
						name: '目标',
						type: 'line',
						data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
					},
				],
			}

			option && myChart.setOption(option)
		},
		histogram2() {
			let myChart = echarts.init(document.getElementById('histogram2'))
			let option = {
				tooltip: {
					// trigger: 'axis',
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#fff', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: ['1', '2', '3', '4', '5'],
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#4c9bfd',
					},
				},
				yAxis: {
					type: 'value',
					// position: 'right',
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#4c9bfd',
					},
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '当前',
						data: [100, 181, 130, 60, 50],
						type: 'bar',
					},
					{
						name: '历史',
						data: [120, 80, 150, 80, 70],
						type: 'bar',
					},
				],
			}

			option && myChart.setOption(option)
		},
		histogram3() {
			let myChart = echarts.init(document.getElementById('histogram3'))
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					top: '20%',
					left: '3%',
					right: '4%',
					bottom: '3%',
					show: true, // 显示边框
					borderColor: '#fff', // 边框颜色
					containLabel: true, // 包含刻度文字在内
				},
				xAxis: {
					type: 'category',
					data: ['1', '2', '3', '4', '5'],
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#4c9bfd',
					},
				},
				yAxis: {
					type: 'value',
					axisTick: {
						show: false,
					},
					axisLabel: {
						color: '#4c9bfd',
					},
					splitLine: {
						lineStyle: {
							color: '#012f4a',
						},
					},
				},
				series: [
					{
						name: '当前',
						data: [
							{
								value: 123,
								itemStyle: {
									color: 'rgb(166, 104, 213)',
								},
							},
							{
								value: 122,
								itemStyle: {
									color: 'rgb(166, 104, 213)',
								},
							},
							{
								value: 118,
								itemStyle: {
									color: 'rgb(166, 104, 213)',
								},
							},
							{
								value: 30,
								itemStyle: {
									color: 'rgb(166, 104, 213)',
								},
							},
							{
								value: 350,
								itemStyle: {
									color: 'rgb(166, 104, 213)',
								},
							},
						],
						type: 'bar',
					},
					{
						name: '目标',
						type: 'line',
						data: [100, 100, 100, 100, 100],
					},
				],
			}

			option && myChart.setOption(option)
		},
	},
	mounted() {
		this.histogram1()
		this.histogram2()
		this.histogram3()
	},
}
</script>

<style lang="scss" scoped>
.mstp-map {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		.tile-title {
			font-size: 20px;
			display: flex;
			justify-content: space-between;
			color: #fff;
			background: rgba(0, 0, 0, 0.65);
			padding: 5px 10px;
			margin-top: 15px;
		}
		.tile {
			.tile-top {
				ul {
					padding: 0;
					display: flex;
					margin: 0;
					li {
						color: #3c8dbc;
						background: rgba(0, 0, 0, 0.65);
						flex: 1;
						font-size: 14px;
						padding: 5px 10px;
					}
					li:hover {
						background: #fff;
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
						cursor: pointer;
						color: #000;
					}
				}
			}
			.tile-details {
				display: flex;
				height: 100%;
				.left {
					display: flex;
					flex: 0.8;
					flex-direction: column;
					background: rgba(0, 0, 0, 0.65);
					span {
						color: #3c8dbc;
						padding: 5px 10px;
						cursor: pointer;
					}
					span:hover {
						border-radius: 5px 5px 0 0;
						color: #000;
						background: #fff;
					}
				}
				.middle {
					flex: 7.4;
					position: relative;
					background: rgba(0, 0, 0, 0.15);
					.histogram1 {
						width: 100%;
						height: 350px;
					}
					.addTime {
						position: absolute;
						top: 15px;
						right: 70px;
					}
					.changeHome {
						position: absolute;
						top: 15px;
						left: 110px;
					}
				}
				.lable {
					background: rgba(0, 0, 0, 0.15);
					flex: 2.8;
					color: #fff;
					span {
						font-size: 14px;
					}
				}
			}
		}
		.form-control {
			border-radius: 0;
			box-shadow: none;
			border-color: #d2d6de;
			background: rgba(0, 0, 0, 0.05);
			border: 1px solid rgba(255, 255, 255, 0.3);
			text-shadow: none;
			color: #fff;
			height: 34px;
			border-radius: 4px;
		}
		.form-control:focus {
			border-color: #3c8dbc;
			box-shadow: none;
		}
		.label {
			display: inline-block;
			height: 34px;
			background: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0.3) 0%,
				rgba(0, 0, 0, 1) 100%
			);
			border: 0;
			color: #fff;
			cursor: pointer;
			font-size: 14px;
			padding: 7px;
			margin-right: 15px;
		}
		.label:hover {
			background-color: #e7e7e7;
		}
		.item {
			display: flex;
			margin-bottom: 20px;
			.box {
				flex: 1;
				color: #fff;
				font-size: 14px;
				margin-right: 15px;
				.box-title {
					display: flex;
					justify-content: space-between;
					padding: 5px;
					background: rgba(0, 0, 0, 0.65);
				}
				.box-content {
					background: rgba(0, 0, 0, 0.35);
					padding: 10px;
					p {
						padding: 10px 50px 0 20px;
						margin: 0;
					}
					.histogram2 {
						width: 475px;
						height: 250px;
					}
					.tab {
						ul {
							padding: 0;
							display: flex;
							li {
								flex: 1;
								height: 35px;
								text-align: center;
								line-height: 35px;
								background: rgba(0, 0, 0, 0.65);
								cursor: pointer;
								color: #3c8dbc;
							}
							.active {
								background: rgba(0, 0, 0, 0.8);
								color: #fff;
							}
							li:hover {
								border-radius: 4px 4px 0 0;
								background: #fff;
								color: #000;
							}
						}
					}
					.nullBox {
						height: 250px;
					}
				}
			}
			.box:last-child {
				margin-right: 0;
			}
		}
	}
}
</style>
